<i18n>
[
    "onboarding__headerLabel--accountCreate",
    "onboarding__headerLabel--profile",
    "onboarding__headerLabel--subscribe",
]
</i18n>
<template>
    <header
        ref="headerRef"
        :class="{
            'c-slide-header': true,
            'o-row-full': true,
            'o-row--fill-content': true,
        }"
    >
        <div
            :class="{
                'c-slide-header__container': true,
                'c-slide-header__container--left': true,
                'c-slide-header__container--full-width': !logoLink,
                'c-slide-header__container--no-progressbar': !showProgressBar,
            }"
        >
            <Component
                :is="logoTag"
                :to="logoRoute"
                class="o-flex-vertical-center"
            >
                <slot name="logo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 146.9" v-bind:svg-inline="''" v-bind:class="'c-slide-header__logo'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M77.3 17.6c12.8 0 22.5 3.8 29.1 11.5 6.6 7.6 9.8 18.9 9.8 33.7v53.4H76.7v-45c0-14.4-5.9-21.6-17.8-21.6-13 0-19.5 7.4-19.5 22.3v44.4H0V21.8h39.1v23.6c3.3-9.5 8.1-16.5 14.3-21 6.1-4.5 14.1-6.8 23.9-6.8M214.1 21.8h39.6v94.4h-39.6V96.7c-6.5 15.3-18.9 22.9-37.3 22.9-12.6 0-22.2-3.5-28.8-10.6-6.6-7-9.9-17.1-9.9-30.3v-57h39.6V69c0 7.2 1.4 12.5 4.3 15.9 2.8 3.4 7.3 5.1 13.3 5.1 12.6 0 18.9-7.3 18.9-21.8V21.8zM333.3 119.5c-19.5 0-34-4.4-43.5-13.2-9.5-8.8-14.3-20.2-14.3-34.1V21.7h39.6v45.7c0 7.2 1.5 12.5 4.4 15.9 2.9 3.4 7.5 5.1 13.9 5.1s11-1.7 13.9-5.1c2.9-3.4 4.4-8.7 4.4-15.9V21.7h39.6v50.5c0 14-4.8 25.4-14.4 34.1-9.9 8.8-24.3 13.2-43.6 13.2M413.3 0h39.4v116.2h-39.4zM558.2 21.8l-22.8 50.8-21.3-50.8H472l41.3 85.9-21.1 39.2h44.5L600 21.8z"/></svg>
                </slot>
            </Component>
        </div>
        <div
            v-if="closeLink"
            :class="{
                'c-slide-header__container': true,
                'c-slide-header__container--center': true,
                'c-slide-header__container--no-progressbar': !showProgressBar,
            }"
        >
            <slot v-if="showProgressBar" name="progressbar"></slot>
        </div>
        <div
            v-if="closeLink"
            :class="{
                'c-slide-header__container': true,
                'c-slide-header__container--right': true,
                'c-slide-header__container--no-progressbar': !showProgressBar,
            }"
        >
            <div v-if="closeLinkIsAFunction">
                <BaseButton v-tab-focus="closeLink" variant="link" class="c-slide-header__close-button">
                    <BaseIcon
                        icon="global--close"
                        size="16px"
                        color="primary"
                    />
                </BaseButton>
            </div>
            <BaseLink v-else-if="!reloadPageOnExit" :to="closeRoute" class="c-slide-header__close-link">
                <BaseIcon
                    icon="global--close"
                    size="16px"
                    color="primary"
                />
            </BaseLink>
            <a v-else :href="closeRoute" class="c-slide-header__close-link">
                <BaseIcon
                    icon="global--close"
                    size="16px"
                    color="primary"
                />
            </a>
        </div>
    </header>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { HEADER_MODULE_NAME, SET_HEADER_HEIGHT } from '~coreModules/header/js/header-store';

import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'SlideHeader',
    components: {
        BaseButton,
    },
    props: {
        closeLink: {
            type: [Object, String, Function],
            default: '/',
        },
        logoLink: {
            type: String,
            default: '/',
        },
        /* if you should refresh the page when clicking the X close icon */
        reloadPageOnExit: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Number,
            default: 0,
        },
        showProgressBar: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['appBaseUrl']),
        ...mapState(HEADER_MODULE_NAME, {
            headerHeight: state => state.headerHeight,
        }),
        logoTag() {
            return this.logoLink ? 'BaseLink' : 'span';
        },
        closeRoute() {
            return this.closeLink === '/' ? this.appBaseUrl : this.closeLink;
        },
        closeLinkIsAFunction() {
            return typeof this.closeLink === 'function';
        },
        logoRoute() {
            return this.logoLink === '/' ? this.appBaseUrl : this.closeLink;
        },
    },
    watch: {
        '$mediaQueries.currentBreakpoint': function onBreakpointChange() {
            const currentHeaderHeight = this.$refs.headerRef.offsetHeight;
            if (currentHeaderHeight !== this.headerHeight) {
                this.setHeaderHeight(currentHeaderHeight);
            }
        },
    },
    mounted() {
        const currentHeaderHeight = this.$refs.headerRef.offsetHeight;
        this.setHeaderHeight(currentHeaderHeight);
    },
    methods: {
        ...mapMutations(HEADER_MODULE_NAME, {
            setHeaderHeight: SET_HEADER_HEIGHT,
        }),
    },
};
</script>

<style lang="scss">

    .c-slide-header {
        position: fixed;
        top: 0;
        height: 48px;
        width: 100%;
        background-color: $nu-white;

        @include breakpoint(medium) {
            height: 64px;
            padding: 0 $nu-spacer-1 0;
        }

        &,
        &__container,
        &__close-link {
            display: flex;
            align-items: center;
        }

        &__container {
            $this-container: &;

            justify-content: center;

            &--left {
                flex: 0 0 80px;

                @include breakpoint(medium) {
                    justify-content: flex-start;
                }

                &#{$this-container}--no-progressbar {
                    @include breakpoint(small) {
                        justify-content: center;
                        flex: 1 1 auto;
                    }
                }
            }

            &--center {
                flex: 0 1 1440px;
                padding: 0 $nu-spacer-1;

                @include breakpoint(small) {
                    padding: 0 $nu-spacer-6;
                }

                @include breakpoint(medium) {
                    padding: 0 $nu-spacer-1;
                }

                @include breakpoint(large) {
                    padding: 0 $nu-spacer-3;
                }

                &#{$this-container}--no-progressbar {
                    padding: 0;

                    @include breakpoint(small) {
                        order: -1;
                    }
                }
            }

            &--right {
                flex: 0 0 auto;

                @include breakpoint(medium) {
                    justify-content: flex-end;
                }
            }

            &--center,
            &--right {
                &#{$this-container}--no-progressbar {
                    @include breakpoint(small) {
                        flex: 0 0 56px;
                    }
                }
            }

            &--left,
            &--right {
                @include breakpoint(medium) {
                    flex: 1 0 auto;
                }
            }

            &--full-width {
                flex-grow: 1;
            }
        }

        &__logo {
            fill: $nu-primary;
            width: 56px;
            margin: 0 $nu-spacer-1 0 $nu-spacer-2;

            @include breakpoint(medium) {
                width: 64px;
                margin: 0 $nu-spacer-3;
            }
        }

        &__close-button,
        &__close-link {
            height: 48px;
            width: 48px;
            flex: 0 0 48px;
            justify-content: center;
            cursor: pointer;
        }
    }
</style>
